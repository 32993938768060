// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://3e9551af2d59444ba560e92a9a7b4c09@o34888.ingest.sentry.io/6276071";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled: !process.env.CI,
  ignoreErrors: [
    /Failed to register a ServiceWorker for scope/,

    // These 3 are spamming sentry, but we _should_ fix them & stop ignoring.
    // Related JIRA tickets: GROWTHRND-1378, GROWTHRND-1379, GROWTHRND-1380.
    /Hydration failed because the initial UI does not match what was rendered on the server/,
    /There was an error while hydrating/,
    /Minified React error #418/,
  ],
});

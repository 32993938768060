export enum CarbonEnvName {
  ALPHA = "alpha",
  BETA = "beta",
  OMEGA = "omega",
  STAGING = "staging",
  PROD = "prod",
  DEV = "dev",
}

export const CarbonEnvs = {
  [CarbonEnvName.ALPHA]: {
    name: CarbonEnvName.ALPHA,
    subdomain: CarbonEnvName.ALPHA,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.BETA]: {
    name: CarbonEnvName.BETA,
    subdomain: CarbonEnvName.BETA,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.OMEGA]: {
    name: CarbonEnvName.OMEGA,
    subdomain: CarbonEnvName.OMEGA,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.STAGING]: {
    name: CarbonEnvName.STAGING,
    subdomain: CarbonEnvName.STAGING,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.PROD]: {
    name: CarbonEnvName.PROD,
    subdomain: null,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.DEV]: {
    name: CarbonEnvName.DEV,
    subdomain: null,
    domain: "localhost:5002",
    protocol: "http",
  },
} as const;

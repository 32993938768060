import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

import {RootState} from "../index";
import {RegionSlug} from "../types";

export type UserLocationState = {
  selectedRegion: RegionSlug;
  selectedStateCode: string;
};

// @ts-expect-error TS2352: Conversion of type '{ selectedRegion: null; selectedStateCode: null; }' to type 'UserLocationState' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
const initialState = {
  selectedRegion: null,
  selectedStateCode: null,
} as UserLocationState;

export const userLocationSlice = createSlice({
  name: "userLocation",
  initialState,
  reducers: {
    setUserLocation: (state, action: PayloadAction<Partial<UserLocationState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.userLocation,
    }),
  },
});

export const {setUserLocation} = userLocationSlice.actions;

export const selectSelectedRegion = (state: RootState) => state.userLocation.selectedRegion;
export const selectSelectedStateCode = (state: RootState) => state.userLocation.selectedStateCode;

export default userLocationSlice.reducer;

import {useTranslation} from "ni18n";
import React, {ComponentProps} from "react";
import {Trans as ReactTrans} from "react-i18next";

type ReactTransProps = ComponentProps<typeof ReactTrans>;

const Trans = (props: Omit<ReactTransProps, "t">): React.ReactElement => {
  const i18n = useTranslation();
  // @ts-expect-error TS2322: Type 'Translate' is not assignable to type 'Translate & TFunction<string | string[], undefined>'.
  return <ReactTrans t={i18n.t} {...props} />;
};

export default Trans;

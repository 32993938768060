import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

export type ReferrerState = string | null;

const initialState = null as ReferrerState;

export const referrerSlice = createSlice({
  name: "referrer",
  initialState,
  reducers: {
    setReferer: (state, action: PayloadAction<ReferrerState>) => action.payload,
  },

  extraReducers: {
    [HYDRATE]: state => state,
  },
});

export const {setReferer} = referrerSlice.actions;

export default referrerSlice.reducer;

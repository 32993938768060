import {configureStore} from "@reduxjs/toolkit";
import {createWrapper} from "next-redux-wrapper";
import {createSelectorHook, useDispatch} from "react-redux";

import {dev} from "../components/_common/_constants";
import config, {setConfig} from "./slices/config";
import featureFlags, {setFeatureFlags} from "./slices/featureFlags";
import isKeyboardUser, {setIsKeyboardUser} from "./slices/isKeyboardUser";
import metadata, {setMetadata} from "./slices/metadata";
import referer, {setReferer} from "./slices/referer";
import regionPricing, {setRegionPricing} from "./slices/regionPricing";
import userLocation, {setUserLocation} from "./slices/userLocation";

export const actions = {
  setIsKeyboardUser,
  setMetadata,
  setReferer,
  setRegionPricing,
  setConfig,
  setFeatureFlags,
  setUserLocation,
};

export const makeStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      featureFlags,
      isKeyboardUser,
      metadata,
      referer,
      regionPricing,
      config,
      userLocation,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    devTools: dev,
    preloadedState,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const useTypedSelector = createSelectorHook<RootState>();
export const useTypedDispatch = () => useDispatch<AppDispatch>();

export const NextReduxWrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
});

import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";

import {CarbonEnvs} from "../../constants/urls";
import {ConfigState} from "../types";

const initialState = {
  imgExt: "webp",
  locations: [],
  host: CarbonEnvs.prod,
} as ConfigState;

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<Partial<ConfigState>>) => ({
      ...state,
      ...action.payload,
    }),
  },

  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.config,
    }),
  },
});

export const {setConfig} = configSlice.actions;

export default configSlice.reducer;

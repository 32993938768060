import queryString from "querystring";

import memoizee from "memoizee";

import {isBrowser, patientAppUrl} from "../components/_common/_constants";
import * as types from "../components/_common/types";
import {PracticeId} from "../constants/practiceIds";
import {virtualSpecialtyIds} from "../constants/specialtyIds";

export const getLoginRedirectUrl = (): string => {
  return `${patientAppUrl}${isBrowser() && window.lng ? `/?lng=${window.lng}&` : ""}/#/login`;
};

export const getSignupRedirectUrl = (): string => {
  return `${patientAppUrl}${isBrowser() && window.lng ? `/?lng=${window.lng}&` : ""}/#/signup`;
};

/**
 * @deprecated The method should not be used. Use `useGetSchedulingRedirectUrl` instead.
 */
export const getSchedulingRedirectUrl = ({
  practiceId,
  apptReasonId,
  locationId,
  virtual,
  time,
  specialtyId,
  preselectedState,
  preselectedLocationIds,
  providerId,
  flowId,
  trafficId,
  appointmentId,
  preselectedApptId,
}: types.GetSchedulingRedirectUrlParams): string => {
  const isVirtual =
    virtual ||
    virtualSpecialtyIds.includes(specialtyId as typeof virtualSpecialtyIds[number]) ||
    (Array.isArray(specialtyId) &&
      specialtyId.every(id =>
        virtualSpecialtyIds.includes(id as typeof virtualSpecialtyIds[number]),
      ));

  const query = queryString.stringify(
    {
      practiceId: practiceId || PracticeId.CARBON,
      apptReasonId,
      locationId: isVirtual ? null : locationId,
      virtual: isVirtual,
      time,
      specialtyIds: Array.isArray(specialtyId) ? specialtyId.join(",") : specialtyId,
      preselectedLocationIds: Array.isArray(preselectedLocationIds)
        ? preselectedLocationIds.join(",")
        : preselectedLocationIds,
      providerId,
      flowId,
      trafficId,
      preselectedState,
      appointmentId,
      preselectedApptId,
    }.pickBy(),
  );

  return `${patientAppUrl}${
    isBrowser() && window.lng ? `/?lng=${window.lng}&` : ""
  }/#/schedule?${query}`;
};

export const memoizedGetSchedulingRedirectUrl = memoizee(getSchedulingRedirectUrl, {
  normalizer: JSON.stringify,
});
